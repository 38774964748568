import { useHomepagePhotos } from "shared/Hooks/homepage/useHomepagePhotos";
import kennmetalVideo from "assets/video/wumax-home.mp4";

export const useEngContactData = () => {
  const photos = useHomepagePhotos();
  return {
    metaInfo: {
      title: "WUMAX - Cutting tools",
      ogDescription:
        "WUMAX supply tools for machining. We are an official Kennametal, Haimer and Walter dealership. We carry a full range of products for the metalworking industry. Our values are Quality, Innovation and modern technology, to bring the best solution for your business. We guarantee exemplary support, ranging from professional consulting in the selection of tools, through comprehensive offers, to shipping and after-sales support.",
    },
    hero: {
      title: "We supply precise solutions to the ",
      titleColor: "metalworking industry.",
      subtitle:
        "WUMAX supply tools for machining. We are an official Kennametal, Haimer and Walter dealership. We carry a full range of products for the metalworking industry. Our values are Quality, Innovation and modern technology, to bring the best solution for your business. We guarantee exemplary support, ranging from professional consulting in the selection of tools, through comprehensive offers, to shipping and after-sales support.",
    },
    sections: [
      {
        title: "Kennametal",
        subtitle:
          "Kennametal is a leading supplier of industrial materials and tools designed for machining. Their products are known for being of the highest quality, precision, and durability, making them the industry leader. The Kennametal brand is a guarantee of high reliability.",
        cta: "Ask our expert",
        checkOffer: "See the full offer",
        slug: "kennametal",
        src: photos.kennametal,
        bigImg: false,
        video: kennmetalVideo,
      },
      {
        title: "Leading producers on the metalworking market",
        subtitle:
          "Our catalog includes renowned brands such as: Haimer, Walter and M.A. Ford. They are top producers in the metalworking industry, known for the highest quality. High standards, high efficiency, and the best parameters – these ideas guided our vision when building our catalog.",
        slug: "/other",
        showMoreText: "Show More",
        gallery: [
          {
            src: photos.haimer,
            big: true,
            alt: "Haimer Logo",
            slug: "/haimer",
          },
          {
            src: photos.walter,
            big: true,
            alt: "Walter Logo",
            slug: "/walter",
          },
          {
            src: photos.maford,
            big: false,
            alt: "M.A. Ford Logo",
            slug: "/others#maford",
          },
          {
            src: photos.yg1,
            big: false,
            alt: "YG1 Logo",
            slug: "/others#yg1",
          },
          {
            src: photos.zccusa,
            big: false,
            alt: "ZCC Logo",
            slug: "/others#zcc",
          },
          {
            src: photos.vargus,
            big: false,
            alt: "Vargus Logo",
            slug: "/others#vargus",
          },
        ],
      },

      {
        title: "The highest quality tools for metalworking",
        subtitle:
          "We carry a wide range of tools for machining: cutting, milling, threading, turning, drilling etc.  Our assortment includes various types of drills, cutters, adapters and other parts for CNC machining. We offer products from well-known and reputable companies, always focused on providing the highest quality for our partners. The most important factor to us is providing the best industry solution and the continual development of our partnerships",
        src: photos.kenTip,
        bigImg: true,
      },
      {
        title: "ToolBOSS Vending Units",
        subtitle:
          "These storage units create an effective Warehouse Logistics and tool distribution system. It optimizes and increases the productivity of your factory.  Saving time, lowering costs, and monitoring tools in real time – these are the benefits of the ToolBOSS vending units.",
        slug: "/toolboss",
        cta: "Ask our expert",
        checkOffer: "See the full offer",
        bigImg: true,
        src: photos.toolboss,
      },
      {
        title: "A good partner to the manufacturing industry",
        subtitle:
          "We focus on innovation and quality, offering services not only in purchasing, but also consulting, tech development and implementation. We offer not only machining tools such as Kennametal, Walter and Haimer, but also ToolBoss tool vending units. We fully support manufacturing with cutting edge tech solutions.",
        src: photos.partner,
        bigImg: true,
      },
    ],
  };
};
