import React from "react";
import { useEngContactData } from "lang/en";
import Homepage from "sections/Homepage/Homepage";

const IndexPage = () => {
  const data = useEngContactData();
  return <Homepage data={data} />;
};

export default IndexPage;
